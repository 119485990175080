import getTailwindConfig from './get-tailwind-config';

export type TailwindBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

const screens = getTailwindConfig('theme.screens') as Record<TailwindBreakpoint, string>;

export function getTailwindBreakpoint(breakpoint: TailwindBreakpoint): string {
  return screens[breakpoint];
}

export default function checkMobileView(breakpoint: TailwindBreakpoint = 'lg'): boolean {
  if (typeof window !== 'undefined') {
    return window?.innerWidth < parseInt(getTailwindBreakpoint(breakpoint), 10);
  }
  return false;
}
