import { PaypalExpressCheckout } from '@services/paypal/types';
import { AuthorizeCheckoutBody } from '@services/paypal/types/AuthorizeCheckoutInput';
import { ExpressCheckoutBody } from '@services/paypal/types/ExpressCheckoutInput';
import type { ExpressPaymentType, StripeElementsOptions } from '@stripe/stripe-js';
import parsePhoneNumberFromString from 'libphonenumber-js';
import merge from 'lodash/merge';
import { TFunction } from 'next-i18next';
import { euStoreLocales } from 'next-i18next.config';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

import { Cart, LineItem } from '@commerce/types/cart';
import { ProductWithPrices } from '@commerce/types/product';
import { PremiumState } from '@components/cart/types/PremiumState';
import { Prices } from '@components/cart/types/Prices';
import { TermsAndConditions } from '@components/checkout/enums/TermsAndConditions';
import { DeliveryMethod } from '@components/checkout/types/DeliveryMethod';
import { PhoneInfo } from '@components/checkout/types/PhoneInfo';
import { validateDob, validateNetsuiteEmail } from '@components/checkout/validations';
import { Address } from '@components/common/types/Address';
import { OrderFeedItem, TurnToOrderFeed } from '@components/common/types/TurnToOrderFeed';
import { ProductBrand, ProductType, SubscriptionTerm, SubscriptionTier } from '@components/product/enums';
import { getPremiumMap, getPremiumSKU } from '@components/product/helpers';
import { SelectOption } from '@components/ui/Select/Select';
import ShippingCountriesStateMapping from '@config/shipping-countries-state-mapping.json';
import { hasLife360Membership } from '@framework/api/utils/cart/validation';
import { CHECKOUT_DATA_LOCAL_STORAGE, ORDER_METADATA_SESSION_STORAGE } from '@framework/const';
import { CountryCode } from '@framework/schema';
import { BillingAddress } from '@framework/types/billing';
import { BcBillingAddress, BigCommerceCheckout } from '@framework/types/checkout';
import { ConsignmentAddress } from '@framework/types/consignment';
import { Country, CountryStates, State } from '@framework/types/country-state';
import callApiRoutes from '@lib/api/call-api-routes';
import { getStorage, setStorage } from '@lib/browser-storage';
import { hashEmail } from '@lib/gtag';
import { pushToGTMDataLayer } from '@lib/gtm';
import { isCALocale as isCALocaleFn, isEmeaLocale as isEmeaLocaleFn, isUSLocale as isUSLocaleFn } from '@lib/locales';
import { Nil } from '@lib/utility-types';
import { maxDigits, minDigits } from '@lib/validators/digits';
import {
  ADDRESS_REGEX,
  ADDRESS1_MAX_LENGTH,
  ALLOWEVERTHING,
  CITY_MAX_LENGTH,
  CITY_REGEX,
  EMAIL_MAX_LENGTH,
  NAME_REGEX,
  NAME_REGEX_US_CA,
  PHONE_NUMBER_REGEX,
} from '@lib/validators/patterns';
import { requiredString } from '@lib/validators/required';

import { PaymentMethodType } from './enums/PaymentMethodType';
import { ShippingMethod } from './enums/ShippingMethod';
import { WalletType } from './enums/WalletType';
import { CheckoutStorageData } from './types/CheckoutStorageData';
import { GiftOptions, UserInfo, UserInfoPayload } from './types/UserInfo';

export function toCents(num: number) {
  // Typescript/JS is very strange with forcing 2 decimals after calculations
  return parseInt(num.toFixed(2).replace('.', ''), 10);
}

export function toDollar(cents: number) {
  return parseFloat(String(cents / 100));
}

export const getAddress = (address?: ConsignmentAddress | null, countryCode?: string, phone?: string): Address => ({
  firstName: address?.first_name || '',
  lastName: address?.last_name || '',
  address1: address?.address1 || '',
  address2: address?.address2 || '',
  zipCode: address?.postal_code || '',
  state: address?.state_or_province || '',
  stateCode: address?.state_or_province_code || '',
  city: address?.city || '',
  country: countryCode || address?.country_code || '',
  phone: address?.phone || phone || '',
});

// following the exact way order consumer is generating fullName for netsuite payload
export const getFullName = (firstName?: string, lastName?: string) => `${firstName || ''} ${lastName || ''}`.trim();

export const isAddressValid = (
  { address1, zipCode, stateCode, city, country, firstName, lastName }: Omit<Address, 'state'>,
  options?: { omitState: boolean }
): boolean => {
  const { omitState } = options || {};
  const valid = !!(address1 && zipCode && city && country && firstName && lastName);

  if (omitState) {
    return valid;
  }
  return valid && !!stateCode;
};

export function getUserInfo({ email, countryCode, checkout, gifts }: UserInfoPayload): UserInfo {
  return {
    email: checkout?.consignments[0]?.shipping_address.email || email || '',
    consignmentId: checkout?.consignments[0]?.id || '',
    shipping: getAddress(checkout?.consignments[0]?.shipping_address, countryCode),
    deliveryMethodId: checkout?.consignments[0]?.selected_shipping_option?.id || '',
    deliveryMethodName: checkout?.consignments[0]?.selected_shipping_option?.description || '',
    paymentMethodType: '',
    stripe: {
      paymentMethodId: '',
      cardType: '',
      cardExpDate: '',
      cardLastFour: '',
    },
    billingId: checkout?.billing_address?.id || '',
    billingAddressType: 'same',
    billing: getAddress(checkout?.billing_address, countryCode),
    orderId: 0,
    checkoutId: checkout?.id || '',
    giftOptions: gifts || {
      giftMessage: '',
      recipientEmail: '',
    },
  };
}

// only set deliveryMethodId and deliveryMethodName if selected_shipping_option is within available_shipping_options
export function getDeliveryMethod(checkout?: BigCommerceCheckout): { id: string; name: string } {
  if (!checkout || !checkout?.consignments?.[0]?.selected_shipping_option?.id) {
    return { id: '', name: '' };
  }

  const availableShipping = checkout?.consignments?.[0]?.available_shipping_options || [];
  const selected = checkout?.consignments?.[0]?.selected_shipping_option;

  if (!availableShipping.find(({ id }) => id === selected.id)) {
    return { id: '', name: '' };
  }

  return { id: selected?.id || '', name: selected?.description || '' };
}

export function getPartialUserInfo({
  email,
  countryCode,
  checkout,
  shippingPhone,
  billingPhone,
}: UserInfoPayload): Partial<UserInfo> {
  const deliveryMethod = getDeliveryMethod(checkout);

  return {
    email: email || checkout?.consignments[0]?.shipping_address.email,
    consignmentId: checkout?.consignments[0]?.id || '',
    shipping: checkout?.consignments[0]?.shipping_address
      ? getAddress(checkout?.consignments[0]?.shipping_address, undefined, shippingPhone)
      : getAddress(undefined, countryCode, shippingPhone),
    deliveryMethodId: deliveryMethod.id,
    deliveryMethodName: deliveryMethod.name,
    billingId: checkout ? checkout?.billing_address?.id || '' : undefined,
    billing: checkout?.billing_address
      ? getAddress(checkout?.billing_address, undefined, billingPhone)
      : getAddress(undefined, countryCode, billingPhone),
    checkoutId: checkout?.id || '',
  };
}

export const getUserInfoCreatedDate = (locale: string): string => {
  const curData = getStorage<CheckoutStorageData>(getStorageKey('userInfo', locale));
  return (curData && typeof curData !== 'string' && curData.created) || new Date().toISOString();
};

const cleanCheckoutStorageData = (curData: CheckoutStorageData): CheckoutStorageData => {
  const { created = new Date().toISOString(), userInfo, lastUpdate } = curData;
  const { paymentMethodType } = userInfo;
  const lifetime = new Date().getTime() - new Date(created).getTime();

  // set all payment info TTL to 1 hour
  if (lifetime < 3600 * 1000) {
    return { userInfo, created, lastUpdate };
  }

  let cleanedUserInfo = { ...userInfo };
  let createdDate = created;

  switch (paymentMethodType) {
    case PaymentMethodType.CREDIT_CARD: {
      cleanedUserInfo = merge(userInfo, {
        paymentMethodType: '',
        stripe: {
          paymentMethodId: '',
          cardType: '',
          cardExpDate: '',
          cardLastFour: '',
        },
      });
      createdDate = new Date().toISOString();
      break;
    }
    case PaymentMethodType.PAYPAL: {
      cleanedUserInfo = merge(userInfo, {
        paymentMethodType: '',
        paypal: null,
      });
      createdDate = new Date().toISOString();
      break;
    }
    default:
      break;
  }

  return { userInfo: cleanedUserInfo, created: createdDate, lastUpdate: new Date().toISOString() };
};

export const getCheckoutDataFromStorage = (locale: string, updateStorage = false): CheckoutStorageData | null => {
  const curData = getStorage<CheckoutStorageData>(getStorageKey('userInfo', locale));

  if (curData && typeof curData !== 'string' && curData?.userInfo) {
    const cleanedCheckoutData = cleanCheckoutStorageData(curData);

    if (updateStorage) {
      setStorage(
        getStorageKey('userInfo', locale),
        {
          userInfo: cleanedCheckoutData.userInfo,
          created: cleanedCheckoutData.created,
          lastUpdate: new Date().toISOString(),
        },
        { manualTrigger: false }
      );
    }

    return cleanedCheckoutData;
  }

  return null;
};

export function isBillingSameAsShipping({ billingAddressType }: Pick<UserInfo, 'billingAddressType'>) {
  return billingAddressType === 'same';
}

export function getCheckoutPrices(
  {
    consignments,
    subtotal_ex_tax: subtotal,
    subtotal_inc_tax: subtotalTaxInc,
    tax_total: checkoutTax,
    shipping_cost_total_ex_tax: shippingCost,
    shipping_cost_total_inc_tax: shippingTaxInc,
    grand_total: totalTaxInc,
  }: BigCommerceCheckout,
  discountAmt?: number | null,
  taxesIncluded?: boolean,
  isDigitalOnly = false
): Prices {
  const addressProvided = consignments?.length > 0;
  const shippingData = consignments?.[0];

  // when option is selected and address is changed to invalidate the selection, BigC won't clear the selected option from checkout data...
  // hence this check to ensure the selected shipping option is valid
  const shippingOptionSelected = !!(
    shippingData?.selected_shipping_option &&
    shippingData?.available_shipping_options?.find(({ id }) => id === shippingData?.selected_shipping_option?.id)
  );
  const shipping = taxesIncluded ? shippingTaxInc : shippingCost;
  const tax = addressProvided || isDigitalOnly || taxesIncluded ? checkoutTax : undefined;
  const discount = typeof discountAmt === 'number' ? discountAmt : undefined;

  // for tax exclusive store, `grand_total` field will include default tax which will be incorrect/confusing to display (when shipping hasn't been provided)
  // hence this calculation
  const total = subtotal - (discount || 0) + (shipping || 0) + (tax || 0);

  return {
    total: taxesIncluded || addressProvided ? totalTaxInc : total,
    tax,
    subtotal: taxesIncluded ? subtotalTaxInc : subtotal,
    shipping: (addressProvided && shippingOptionSelected) || isDigitalOnly ? shipping : undefined,
    discount,
  };
}

export function getSelectOptions({
  countries,
  states,
  allCountries,
}: CountryStates & { allCountries?: Country[] }): Record<string, SelectOption[]> {
  return {
    countries: countries.map(({ countryCode: key, country: value }) => ({ key, value })),
    allCountries: allCountries?.map(({ countryCode: key, country: value }) => ({ key, value })) || [],
    states: states.map(({ stateCode: key, state: value }) => ({ key, value })),
  };
}

export function getStorageKey(type: 'metadata' | 'userInfo', locale: string) {
  switch (type) {
    case 'userInfo':
      return `${locale}_${CHECKOUT_DATA_LOCAL_STORAGE}`;
    case 'metadata':
      return `${locale}_${ORDER_METADATA_SESSION_STORAGE}`;
    default:
      return '';
  }
}

export function getAddressPayload<T = BillingAddress>(
  { firstName, lastName, address1, address2, city, stateCode, country, zipCode, phone }: Omit<Address, 'state'>,
  email: string
) {
  const payload = {
    first_name: firstName,
    last_name: lastName,
    email,
    address1,
    address2,
    city,
    state_or_province_code: stateCode,
    country_code: country,
    postal_code: zipCode,
    phone: phone || '',
  };

  return payload as T;
}

export function getConsignmentPayload(address: Omit<Address, 'state'>, email: string, cart?: Cart | null) {
  return {
    shipping_address: getAddressPayload<ConsignmentAddress>(address, email),
    line_items:
      cart?.lineItems.map((item) => ({
        item_id: item.id,
        quantity: item.quantity,
      })) || [],
  };
}

// for shipping address payload on order metafields
export function getAddressMetadata(
  { firstName, lastName, address1, address2, city, stateCode, state, country, zipCode, phone }: Address,
  email: string,
  shipping = ShippingMethod.FREE
) {
  return {
    first_name: firstName,
    last_name: lastName,
    email,
    street_1: address1,
    street_2: address2,
    city,
    state,
    stateCode,
    country_iso2: country,
    zip: zipCode,
    phone,
    shipping_method: shipping,
  };
}

export const getListOfAvailableShippingMethods = (methods: DeliveryMethod[] | undefined) => {
  const isFreeShippingAvailable = methods?.find((m) => m.value === ShippingMethod.FREE);
  return isFreeShippingAvailable ? methods?.filter((m) => m.value !== ShippingMethod.STANDARD) : methods;
};

export function parseStripeAddress(address: Address) {
  return {
    city: address.city,
    country: address.country,
    line1: address.address1,
    line2: address.address2,
    postal_code: address.zipCode,
    state: address.stateCode,
  };
}

export function parseStripeShippingAddress(shippingAddress: Address) {
  return {
    shipping: {
      address: parseStripeAddress(shippingAddress),
      name: `${shippingAddress.firstName} ${shippingAddress.lastName}`,
      phone: shippingAddress.phone,
    },
    name: `${shippingAddress.firstName} ${shippingAddress.lastName}`,
    phone: shippingAddress.phone,
  };
}

export const parseStripeBillingAddress = (billingAddress: Address, userEmail: string) => ({
  address: parseStripeAddress(billingAddress),
  email: userEmail.replace(/\s/g, ''),
  name: `${billingAddress.firstName} ${billingAddress.lastName}`,
});

export const getWalletAddress = (userInfo: UserInfo) => {
  switch (userInfo.paymentMethodType) {
    case PaymentMethodType.PAYPAL:
      return userInfo.paypal?.email;
    // stripe wallets prob not needed as it'll go to order submit/confirmed once paid
    case PaymentMethodType.APPLE_PAY:
      return userInfo.applePay || '';
    case PaymentMethodType.GOOGLE_PAY:
      return userInfo.googlePay || '';
    default:
      return '';
  }
};

export function getIntegrationNotesFromType(
  paymentMethodType: PaymentMethodType | ''
): Record<string, string | boolean> {
  let notes: Record<string, string | boolean> = {};
  switch (paymentMethodType) {
    // deprecated for apple/google pay, it'll be using `getIntegrationNotesFromResult`
    case PaymentMethodType.APPLE_PAY:
      notes = { payment_provider: PaymentMethodType.CREDIT_CARD, wallet: WalletType.APPLE_PAY };
      break;
    case PaymentMethodType.GOOGLE_PAY:
      notes = { payment_provider: PaymentMethodType.CREDIT_CARD, wallet: WalletType.GOOGLE_PAY };
      break;
    case PaymentMethodType.PAYPAL: {
      notes = { payment_provider: PaymentMethodType.PAYPAL };
      break;
    }
    case PaymentMethodType.CREDIT_CARD: {
      notes = { payment_provider: PaymentMethodType.CREDIT_CARD };
      break;
    }
    default:
      break;
  }

  return notes;
}

export function isPaymentRequired(totalPrice: number, hasPremium: boolean): boolean {
  return totalPrice > 0 || hasPremium;
}

export function getIntegrationNotesFromExpressPayment(
  paymentType: ExpressPaymentType
): Record<string, string | boolean> {
  return { payment_provider: PaymentMethodType.CREDIT_CARD, wallet: paymentType };
}

export async function setPaypalExpressCheckout(
  locale: string,
  data: ExpressCheckoutBody
): Promise<{ token: string; widgetUrl: string }> {
  const resp = await callApiRoutes<{ data: { token: string; widgetUrl: string } }>(
    `/api/paypal/nvp/express-checkout?locale=${locale}`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );

  return resp.data;
}

export async function getPaypalExpressCheckout(locale: string, token: string): Promise<PaypalExpressCheckout> {
  const resp = await callApiRoutes<{ data: PaypalExpressCheckout }>(
    `/api/paypal/nvp/express-checkout?locale=${locale}&token=${token}`
  );
  return resp.data;
}

export async function authorizePaypalExpressCheckout(
  locale: string,
  token: string,
  data: AuthorizeCheckoutBody
): Promise<{ transactionId: string; billingAgreementId?: string }> {
  const resp = await callApiRoutes<{ data: { transactionId: string; billingAgreementId?: string } }>(
    `/api/paypal/nvp/authorize?locale=${locale}&token=${token}`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );

  return resp.data;
}

export async function createBillingAgreement(locale: string, token: string): Promise<string> {
  const resp = await callApiRoutes<{ data: string }>(
    `/api/paypal/nvp/billing-agreement?locale=${locale}&token=${token}`,
    {
      method: 'POST',
    }
  );

  return resp.data;
}

export async function checkSubscriptionStatus(locale: string): Promise<{ premiumState: boolean; freeTrial: boolean }> {
  const resp = await callApiRoutes<{ data: { premiumState: boolean; freeTrial: boolean } }>(
    `/api/customer/subscription-status?locale=${locale}`,
    {
      method: 'GET',
    }
  );

  return resp.data;
}

export function getGACommercePayload(
  orderId: string | number,
  cart: Nil<Cart>,
  currency: string,
  prices: Prices,
  locale: string,
  storeHash: string,
  page_type: string,
  payment_source?: string | null,
  payment_status?: string,
  error_reason?: string
) {
  const items =
    cart?.lineItems?.map(({ name, quantity, variant: { sku, price } }) => ({
      id: sku,
      name,
      brand: ProductBrand.TILE,
      quantity,
      price,
      page_type,
      payment_source,
      payment_status,
      error_reason,
    })) || [];

  return {
    transaction_id: `${orderId}`,
    affiliation: 'Tile eCommerce',
    value: prices.total,
    currency: currency.toLocaleUpperCase(),
    tax: prices.tax,
    discount: prices.discount || 0,
    locale,
    storeHash,
    shipping: prices.shipping,
    items,
  };
}

export function isGiftOptionEmpty(option?: GiftOptions): boolean {
  if (!option) {
    return true;
  }

  return !option?.giftMessage && !option?.recipientEmail;
}

export const trimValue = (value?: string) => value?.trim() ?? '';

export const getCountryStates = async (countryCode: string): Promise<State[]> => {
  if (countryCode in ShippingCountriesStateMapping) {
    return ShippingCountriesStateMapping[countryCode];
  }
  const result = await callApiRoutes<{ data: CountryStates }>(`/api/country-state?countryCode=${countryCode}`);
  return result.data.states || [];
};

export function isBigCAddressValid({
  address1,
  postal_code,
  first_name,
  last_name,
  city,
  country_code,
  state_or_province_code: stateCode,
}: ConsignmentAddress | BcBillingAddress): boolean {
  const shouldHaveState = !!country_code && ShippingCountriesStateMapping?.[country_code]?.length > 0;

  return !!(
    address1?.trim() &&
    postal_code?.trim() &&
    city?.trim() &&
    first_name?.trim() &&
    last_name?.trim() &&
    country_code?.trim() &&
    (shouldHaveState ? stateCode?.trim() : true)
  );
}

export function isAddressOnlyValid({
  address1,
  zipCode,
  city,
  country,
  stateCode,
}: Omit<Address, 'firstName' | 'lastName' | 'phone'>): boolean {
  const shouldHaveState = !!country && ShippingCountriesStateMapping?.[country]?.length > 0;

  return !!(
    address1?.trim() &&
    zipCode?.trim() &&
    city?.trim() &&
    country?.trim() &&
    (shouldHaveState ? stateCode?.trim() : true)
  );
}

export const getEmailValidationRules = (t: TFunction) => ({
  required: t('common:error.empty.email'),
  validate: {
    value: (email: string) => validateNetsuiteEmail(email).success || t('auth:error.invalid_email').toString(),
  },
  maxLength: { value: EMAIL_MAX_LENGTH, message: t('checkout:errors.email_too_long').toString() },
});

export const getPhoneValidationRule = (t: TFunction) => ({
  // basic phone validation and no display formatting
  // use https://github.com/ruimarinho/google-libphonenumber for location specific validation + display formatting
  validate: {
    required: requiredString(t('common:error.empty.phone')),
    minNumberLength: (v: string) => minDigits(7)(v) || t('checkout:errors.phone.too_short'),
    maxNumberLength: (v: string) => maxDigits(11)(v) || t('checkout:errors.phone.too_long', { length: 11 }),
  },
  pattern: { value: PHONE_NUMBER_REGEX, message: t('checkout:errors.phone.invalid') },
});

export const getNameValidationRules = (t: TFunction, isUsOrCaLocale = false) => {
  return {
    firstName: {
      validate: requiredString(t('common:error.empty.firstName')),
      pattern: {
        value: isUsOrCaLocale ? NAME_REGEX_US_CA : NAME_REGEX,
        message: t('common:error.invalid_name'),
      },
      setValueAs: trimValue,
    },
    lastName: {
      validate: requiredString(t('common:error.empty.lastName')),
      pattern: {
        value: isUsOrCaLocale ? NAME_REGEX_US_CA : NAME_REGEX,
        message: t('common:error.invalid_name'),
      },
      setValueAs: trimValue,
    },
  };
};

export const getDobValidationRules = (t: TFunction) => ({
  required: t('common:error.empty.dob'),
  validate: {
    value: (dob: Date | string | null) => validateDob(dob) || t('common:error.invalid_dob').toString(),
  },
});

export const getAddressOnlyValidationRules = (country: string, locale: string, t: TFunction) => {
  const isUSLocale = isUSLocaleFn(locale);
  const isCALocale = isCALocaleFn(locale);
  const isEmeaLocale = isEmeaLocaleFn(locale);

  return {
    address1: {
      validate: requiredString(t('common:error.empty.address1')),
      ...(!isEmeaLocale
        ? {
            pattern: {
              value: isUSLocale || isCALocale ? ADDRESS_REGEX : ALLOWEVERTHING,
              message: t('common:error.invalid_address'),
            },
          }
        : null),
      maxLength: { value: ADDRESS1_MAX_LENGTH, message: t('common:error.address1_longer') },
      setValueAs: trimValue,
    },
    ...(!isEmeaLocale
      ? {
          address2: {
            pattern: {
              value: isUSLocale || isCALocale ? ADDRESS_REGEX : ALLOWEVERTHING,
              message: t('common:error.invalid_address'),
            },
            maxLength: { value: ADDRESS1_MAX_LENGTH, message: t('common:error.address1_longer') },
            setValueAs: trimValue,
          },
        }
      : null),
    city: {
      validate: requiredString(t('common:error.empty.city')),
      ...(!isEmeaLocale
        ? {
            pattern: {
              value: isUSLocale || isCALocale ? CITY_REGEX : ALLOWEVERTHING,
              message: t('common:error.invalid_city'),
            },
          }
        : null),
      maxLength: { value: CITY_MAX_LENGTH, message: t('common:error.city_longer') },
      setValueAs: trimValue,
    },
    stateCode: {
      validate: requiredString(t('common:error.empty.stateCode')),
    },
    zipCode: {
      validate: {
        required: requiredString(t('common:error.empty.zipCode')),
        validZip: (value?: string) =>
          postcodeValidatorExistsForCountry(country)
            ? postcodeValidator(value ?? '', country) || t('common:error.invalid_zipcode')
            : true,
      },
      setValueAs: trimValue,
    },
    country: {
      validate: requiredString(t('common:error.empty.country')),
    },
  };
};

export const getAddressValidationRules = (
  country: string,
  locale: string,
  t: TFunction,
  type: 'shipping' | 'billing' = 'shipping'
) => {
  const isUSLocale = isUSLocaleFn(locale);
  const isCALocale = isCALocaleFn(locale);

  return {
    ...getNameValidationRules(t, isUSLocale || isCALocale),
    ...getAddressOnlyValidationRules(country, locale, t),
    ...(type === 'shipping'
      ? {
          phone: getPhoneValidationRule(t),
        }
      : null),
  };
};

export function getTermsAndConditions(
  termsAndConditions: Record<TermsAndConditions, string>,
  { isPremiumOnly = false, hasL360Premium = false, hasPremium = false, premiumPrice = '', hasJiobit = false }
) {
  let type = TermsAndConditions.HARDWARE;

  if (hasPremium) {
    type = TermsAndConditions.HARDWARE_AND_PREMIUM;
  }

  if (isPremiumOnly) {
    type = TermsAndConditions.PREMIUM;
  }

  if (hasL360Premium) {
    type = TermsAndConditions.L360_PREMIUM;
  }

  if (hasJiobit) {
    type = TermsAndConditions.JIOBIT_HARDWARE;
  }

  return (termsAndConditions[type] || '').replace(/{{price.*}}/gi, premiumPrice);
}

export function getTncCypressKey({ isPremiumOnly = false, hasPremium = false }) {
  if (isPremiumOnly) {
    return 'tnc-premium';
  }

  if (hasPremium) {
    return 'tnc-bundle';
  }

  return 'tnc-hardware';
}

export function getSubscriptionPrice(
  prices: ProductWithPrices[],
  tier?: SubscriptionTier,
  term?: SubscriptionTerm,
  locale?: string
): number {
  if (!tier) {
    return 0;
  }

  const sku = getPremiumSKU(getPremiumMap(locale), tier, term);
  return (sku && prices.find((price) => price.sku === sku)?.prices?.retailPrice?.value) || 0;
}

export function isTileGpsCheckout(lineItems: LineItem[], locale: string): boolean {
  return hasLife360Membership(lineItems, locale) && lineItems.some(({ type }) => type === ProductType.PHYSICAL);
}

export function checkPremium(
  premiumData: PremiumState['premiumData'],
  lineItems: LineItem[],
  locale: string
): Pick<PremiumState, 'hasTilePremium' | 'hasL360Premium'> {
  return {
    hasTilePremium: !!premiumData && premiumData.brand === ProductBrand.TILE,
    hasL360Premium:
      (!!premiumData && premiumData.brand === ProductBrand.L360) || isTileGpsCheckout(lineItems || [], locale),
  };
}

// session key to track page where payment has been selected for the events
export function setKeyToSession(key: string, value: string): void {
  const itemStr = sessionStorage.getItem(key);

  if (itemStr) {
    const updateItem = {
      value,
    };
    sessionStorage.setItem(key, JSON.stringify(updateItem));
  } else {
    sessionStorage.setItem(key, JSON.stringify({ value }));
  }
}

export function getSessionKeyValue(key: string): string | null {
  const itemStr = sessionStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);

  return item.value;
}

export function deleteSessionKey(key: string): void {
  sessionStorage.removeItem(key);
}

export function buildFullPhoneNumber(phone?: string, countryCode: string = '1'): string {
  return phone ? `+${countryCode}${phone}` : '';
}

export function parsePhoneNumber(phone: string): PhoneInfo | null {
  // parse phone from form input
  const fullPhoneNumber = `+${phone}`;
  const parsedPhone = parsePhoneNumberFromString(fullPhoneNumber);
  if (parsedPhone) {
    const { countryCallingCode, nationalNumber } = parsedPhone;
    return {
      fullPhoneNumber,
      countryCode: countryCallingCode,
      nationalNumber,
    };
  }

  return null;
}

export function getCountryCode(storeCountryCode: string, locale?: string): string {
  if (!locale || !euStoreLocales.includes(locale)) {
    return storeCountryCode;
  }

  const euCountryCodeFromLocale = locale.split('-')[1]?.toUpperCase() || '';
  return Object.values(CountryCode).includes(euCountryCodeFromLocale as any)
    ? euCountryCodeFromLocale
    : storeCountryCode;
}

export function getStripeElementOptions(extraOptions?: Partial<StripeElementsOptions>): StripeElementsOptions {
  return {
    mode: 'payment',
    paymentMethodCreation: 'manual',
    captureMethod: 'manual',
    ...extraOptions,
  } as StripeElementsOptions;
}

export function getStripeAmount(totalAmount: number, displayAmount?: number): number {
  const amtInCents = toCents(totalAmount);
  // stripe elements will throw error if amount is 0
  // and won't render express checkout with amount less than 50 cents
  return amtInCents || displayAmount || 50;
}

export function showStripePayment(totalAmount: number) {
  // hide stripe payments when order total amount is > 0 and < $1
  return totalAmount === 0 || totalAmount >= 1;
}

export const getTurnToOrderFeedData = (
  cart: Cart | null,
  shipping: Address | null,
  orderId: number,
  email: string
): TurnToOrderFeed => {
  if (!cart?.lineItems) {
    return null;
  }

  const items: OrderFeedItem[] = cart.lineItems.map((item) => ({
    title: item.variant.name,
    itemImageUrl: item?.variant?.image?.url ? item?.variant?.image.url : '',
    lineItemId: item.id,
    sku: item.variant.sku,
    qty: item.quantity,
    price: item.variant.price,
    siteKey: process.env.NEXT_PUBLIC_TURNTO_SITEKEY, // Include any env variable here
  }));

  return {
    orderId,
    email,
    firstName: shipping?.firstName || '',
    lastName: shipping?.lastName || '',
    items,
  };
};

export const getBrazeSmsOptInPayload = (email: string, phone: string, lineItems: LineItem[]) => ({
  email,
  source: 'smsCheckout',
  phone,
  cartItems: lineItems.map(({ name, quantity, variant: { sku, price } }) => ({
    name,
    sku,
    price,
    quantity,
  })),
});

export const getBrazeEmailOptInPayload = (
  email: string,
  lineItems: LineItem[],
  firstName?: string,
  phone?: string
) => ({
  firstName,
  email,
  source: 'emeaEmailCheckout',
  phone,
  cartItems: lineItems.map(({ name, quantity, variant: { sku, price } }) => ({
    name,
    sku,
    price,
    quantity,
  })),
});

export function pushOrderToGTM(
  orderId: number | undefined,
  email: string,
  shipping: Address | undefined,
  cart: Nil<Cart>,
  currency: string,
  prices: Prices,
  storeHash: string
) {
  pushToGTMDataLayer({
    orderId: `${storeHash}-${orderId || ''}`,
    orderEmail: email,
    hashedOrderEmail: hashEmail(email),
    orderProductIDs: cart?.lineItems?.map((li) => li.variant?.sku),
    orderProductNames: cart?.lineItems?.map((li) => li.variant?.name),
    orderPromoCode: cart?.coupons?.map((c) => c.code),
    orderCurrency: currency,
    orderDiscount: prices.discount,
    orderShipping: prices.shipping,
    orderTax: prices.tax,
    orderTotal: prices.total,
    orderPhone: shipping?.phone || '',
    orderFirstName: shipping?.firstName || '',
    orderLastName: shipping?.lastName || '',
    orderAddress: shipping?.address1 || '',
    orderCity: shipping?.city || '',
    orderZip: shipping?.zipCode || '',
    orderState: shipping?.state || '',
    orderCountry: shipping?.country || '',
    orderItems:
      cart?.lineItems?.map(({ name, quantity, variant: { sku, price } }) => ({
        id: sku,
        name,
        // TODO should be based on LineItem product brand
        brand: ProductBrand.TILE,
        quantity,
        price,
      })) || [],
  });
}

export function navigatorInfo() {
  const browserAgent = navigator.userAgent;
  let browserName = navigator.appName || 'unknown';
  let browserVersion = '' + parseFloat(navigator.appVersion);
  let Offset, OffsetVersion, ix;

  //For Instagram in app browser
  if (browserAgent.indexOf('Instagram') != -1) {
    browserName = 'instagramBrowser';
    //regex to get version
    const pattern = /Instagram (\d+\.\d+)/;
    const match = pattern.exec(browserAgent);
    browserVersion = match ? match[1] : '1.0';
  }

  // For Chrome
  else if ((OffsetVersion = browserAgent.indexOf('Chrome')) != -1) {
    browserName = 'chrome';
    browserVersion = browserAgent.substring(OffsetVersion + 7);
  }

  // For Microsoft internet explorer.
  else if ((OffsetVersion = browserAgent.indexOf('MSIE')) != -1) {
    browserName = 'microsoft';
    browserVersion = browserAgent.substring(OffsetVersion + 5);
  }

  // For Firefox
  else if ((OffsetVersion = browserAgent.indexOf('Firefox')) != -1) {
    browserName = 'firefox';
    browserVersion = browserAgent.substring(OffsetVersion + 8);
  }

  // For Safari
  else if ((OffsetVersion = browserAgent.indexOf('Safari')) != -1) {
    browserName = 'safari';
    browserVersion = browserAgent.substring(OffsetVersion + 7);
    if ((OffsetVersion = browserAgent.indexOf('Version')) != -1)
      browserVersion = browserAgent.substring(OffsetVersion + 8);
  }

  // For Facebook in app browser
  else if (browserAgent.match(/FBAN|FBAV/i)) {
    browserName = 'facebookBrowser';
    browserVersion = '1.0';
  }

  // For other browser "name/version" is at the end of userAgent
  else if ((Offset = browserAgent.lastIndexOf(' ') + 1) < (OffsetVersion = browserAgent.lastIndexOf('/'))) {
    browserName = browserAgent.substring(Offset, OffsetVersion);
    browserVersion = browserAgent.substring(OffsetVersion + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName || 'unknown';
    }
  }

  // Trimming the fullVersion string at
  // semicolon/space if present
  if ((ix = browserVersion.indexOf(';')) !== -1) browserVersion = browserVersion.substring(0, ix);
  if ((ix = browserVersion.indexOf(' ')) !== -1) browserVersion = browserVersion.substring(0, ix);

  return `${browserName}/${browserVersion}`;
}

// adding phone numbers to session in OTP flow
export const setNumberToSession = (num: number) => {
  // Retrieve existing numbers from sessionStorage
  const storedNumbers = sessionStorage.getItem('storedNumbers');
  const numbersArray: number[] = storedNumbers ? JSON.parse(storedNumbers) : [];

  if (!numbersArray.includes(num)) {
    numbersArray.push(num);
    sessionStorage.setItem('storedNumbers', JSON.stringify(numbersArray));
  }
};

export const numberExistsInSession = (num: number): boolean => {
  const storedNumbers = sessionStorage.getItem('storedNumbers');
  if (!storedNumbers) return false;

  const numbersArray: number[] = JSON.parse(storedNumbers);
  return numbersArray.includes(num);
};

export const excludeCountries = (countriesList: { key: string; value: string }[]): { key: string; value: string }[] => {
  const excludeCountriesList: string[] = ['RU', 'CU', 'VE', 'AF', 'SY', 'KP', 'IR'];
  return countriesList.filter(({ key }) => !excludeCountriesList.includes(key));
};
