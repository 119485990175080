import { CSSProperties } from 'react';

import { AdSpacer } from '@components/product/types/Ads';
import Link from '@components/ui/Link';
import { getContentfulImgSet, renderImage, renderResponsiveImage } from '@lib/image';

import s from './ListingSpacer.module.scss';

type Props = AdSpacer & {
  spacersNumber: number;
  tilesNumber: number;
};

interface SpacerRowCSSProperties extends CSSProperties {
  '--spacer-grid-row-mobile': number;
  '--spacer-grid-row-desktop': number;
}

export const MOBILE_TILES_PER_ROW = 2;
export const DESKTOP_TILES_PER_ROW = 3;

export const ListingSpacer = ({
  title,
  description,
  image,
  mobileImage,
  fullSizeImage = false,
  onClickUrl = '/',
  spacerPosition,
  spacersNumber,
  tilesNumber,
}: Props) => {
  const mobileRowsPerPage = Math.ceil(tilesNumber / MOBILE_TILES_PER_ROW) + spacersNumber;
  const desktopRowsPerPage = Math.ceil(tilesNumber / MOBILE_TILES_PER_ROW) + spacersNumber;

  const rowMobile = spacerPosition > mobileRowsPerPage ? mobileRowsPerPage + 1 : spacerPosition;
  const rowDesktop = spacerPosition > desktopRowsPerPage ? desktopRowsPerPage + 1 : spacerPosition;

  const cssProperties: SpacerRowCSSProperties = {
    '--spacer-grid-row-mobile': rowMobile,
    '--spacer-grid-row-desktop': rowDesktop,
  };

  return (
    <div
      className={s.spacerRow}
      style={cssProperties}
      data-testid="listing-spacer"
      data-listing-spacer-row-mobile={rowMobile}
      data-listing-spacer-row-desktop={rowDesktop}
    >
      {fullSizeImage && onClickUrl ? (
        <Link href={onClickUrl} prefetch={false} className="h-[120px] md:h-[100px] p-4 relative block overflow-hidden">
          {renderResponsiveImage(getContentfulImgSet(image, mobileImage), {
            className: `aspect-auto w-full h-full absolute object-cover inset-0`,
            loading: 'lazy',
            width: '100%',
            height: 'auto',
          })}
        </Link>
      ) : (
        <article className="lg:py-4 relative">
          <div className="flex items-center gap-y-4 flex-col lg:block">
            {renderImage(image, {
              className: 'lg:absolute lg:right-0 lg:max-h-full lg:top-0 aspect-[170/70]',
              loading: 'lazy',
              width: 170,
              height: 70,
            })}
            <section className="text-center px-4 pb-4 lg:px-0 lg:pb-0">
              <h4 className="text-[16px] leading-none lg:text-[21.28px] lg:leading-[26.6px] font-bold mb-2 lg:mb-3">
                {title}
              </h4>
              <p className="font-normal text-[12px] lg:text-[16px] text-purple">{description}</p>
            </section>
          </div>
        </article>
      )}
    </div>
  );
};
