import { useMediaQuery } from 'react-responsive';

import { getTailwindBreakpoint, TailwindBreakpoint } from '@lib/check-mobile-view';

// Hook to determine if the screen width matches the specified condition.
export const useMatchMedia = (width: string) => {
  const isMatch = useMediaQuery({ maxWidth: width });
  return isMatch;
};

export const useMatchTailwindMedia = (breakpoint: TailwindBreakpoint) => {
  const isMatch = useMediaQuery({ maxWidth: getTailwindBreakpoint(breakpoint) });
  return isMatch;
};
